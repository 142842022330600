html {
  overflow-x: initial !important;
}

html,body {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  overflow-x: hidden;
  word-break: break-word;
  
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.app-container * {
  font-size: large;
}

.app-parent{
  height: 99vh;
  display: grid;
  grid-template-rows: 50px auto 80px;
}

@media(width < 520px){
  .app-parent{
    grid-template-rows: 50px auto 130px;
  }
}

@media(width < 400px){
  .app-parent{
    grid-template-rows: 50px auto 170px;
  }
}

@media(width < 200px){
  .app-parent{
    grid-template-rows: 50px auto 200px;
  }
}

.app-parent > *{
  display:flex;
  justify-content: center;
  align-items: center;
}

.background-color{
  background-color: #fffcf0;
}
